<template>
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-row v-if="orderDetail">
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="shipment.first_name"
                label="First Name "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="shipment.last_name"
                label="Last Name "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field v-model="shipment.email" label="Email " dense outlined></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="shipment.primary_phone"
                label="Phone "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="shipment.secondary_phone"
                label="Secondary Phone "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-textarea v-model="shipment.message" label="Message " dense outlined></v-textarea>
          </v-col>
        </v-row>
        <!--        User Address starts from here-->
        <v-row>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="address.address_line_1"
                label="Address Line 1 "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="address.address_line_2"
                label="Address Line 2 "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="address.area"
                label="Area "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="address.city"
                label="City "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <v-text-field
                v-model="address.state"
                label="State "
                dense
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn @click.prevent="updateDetailAddress" outlined class="pull-right" :loading="loading">Update</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "customer",
  props: ["orderDetail", "loading"],
  data() {
    return {
      address: {
        address_line_1: null,
        address_line_2: null,
        area: null,
        city: null,
        state: null,
      },
      shipment: {},
    }
  },
  mounted() {
    if (this.orderDetail.shipment)
      this.shipment = this.orderDetail.shipment
    if (this.orderDetail.shipment && this.orderDetail.shipment.user_address)
      this.address = this.orderDetail.shipment.user_address
  },
  methods: {
    updateDetailAddress() {
      // let data = {
      //   first_name: (this.orderDetail && this.orderDetail.shipment) ? this.orderDetail.shipment.first_name : null,
      //   last_name: (this.orderDetail && this.orderDetail.shipment) ? this.orderDetail.shipment.last_name : null,
      //   email: (this.orderDetail && this.orderDetail.shipment) ? this.orderDetail.shipment.email : null,
      //   primary_phone: (this.orderDetail && this.orderDetail.shipment) ? this.orderDetail.shipment.primary_phone : null,
      //   secondary_phone: (this.orderDetail && this.orderDetail.shipment) ? this.orderDetail.shipment.secondary_phone : null,
      //   message: (this.orderDetail && this.orderDetail.shipment) ? this.orderDetail.shipment.message : null,
      //   address: (this.orderDetail && this.orderDetail.shipment) ? this.orderDetail.shipment.user_address : null,
      //   buyer_id: this.orderDetail.buyer_id,
      // };
      this.shipment.address = this.address;
      this.shipment.buyer_user_id = this.orderDetail.buyer_user_id;
      this.shipment.country_id = this.orderDetail.country_id;
      this.$emit("save_shipping", this.shipment);
    }
  }
};
</script>
