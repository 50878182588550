<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="8" sm="12" md="8" lg="8">
          <v-card flat v-if="shippment.package_ids.length<=0">
            <v-card-title>Available Packages</v-card-title>
            <v-card-text>
              <v-simple-table class="packages-table" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">QTY</th>
                      <th class="text-left">L</th>
                      <th class="text-left">W</th>
                      <th class="text-left">H</th>
                      <th class="text-left">Actual WGT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,i) in packages" :key="i">
                      <td>
                        <div class="d-flex align-items-center">
                          <v-checkbox v-model="item.enable" @change="enablePackageItem(i,item)"></v-checkbox>
                          <v-text-field
                            v-model="item.qty"
                            type="number"
                            dense
                            @change="enablePackageItem(i,item)"
                            outlined
                            class="qty"
                            label="qty"
                          ></v-text-field>
                          <span>X {{item.title}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <v-text-field v-model="item.length" dense outlined label="length"></v-text-field>
                          <span>X</span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <v-text-field v-model="item.width" dense outlined label="width"></v-text-field>
                          <span>X</span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <v-text-field v-model="item.height" dense outlined label="height"></v-text-field>
                          <span>{{item.measurement_unit}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <v-text-field dense v-model="item.weight" outlined label="weight"></v-text-field>
                          <span>{{ item.weight_unit }}</span>
                        </div>
                      </td>
                    </tr>
                    <v-btn outlined dense class="mt-10" @click="addPackages()" text>Add New Package</v-btn>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" sm="12" md="4" lg="4">
          <v-card>
            <v-card-title class="text-center">Shipping Information</v-card-title>
            <v-card-text>
              <div class="d-flex justify-content-around">
                <Strong v-if="orderDetail.delivery_amount">
                  Delivery Type
                  <br />

                  <v-chip small label>{{orderDetail.delivery_title}}</v-chip>
                  <br />

                  <Strong>{{ delivery_type}}</Strong>
                </Strong>
                <Strong v-if="orderDetail.delivery_amount">
                  Shipping Price
                  <br />
                  {{orderDetail.cart_total.symbol}} {{ orderDetail.delivery_amount }}
                </Strong>
                <strong>
                  Total Weight
                  {{totalWeight}} {{default_unit }}
                </strong>
              </div>

              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" md="12" sm="12" v-if="shippment.tracking_code">
                  <v-row>
                    <v-col cols="6" md="6" sm="6">
                      <h5>Tracking Code:</h5>
                    </v-col>
                    <v-col cols="6" md="6" sm="6">
                      <h5>{{shippment.tracking_code}}</h5>
                    </v-col>
                  </v-row>
                </v-col>
                <br />
                <v-col cols="12" md="12" sm="12" v-if="shippment.tracking_url">
                  <v-row>
                    <v-col cols="6" md="6" sm="6">
                      <h5>Tracking Url :</h5>
                    </v-col>
                    <v-col cols="6" md="6" sm="6">
                      <h5>{{shippment.tracking_url}}</h5>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12" v-if="shippment.picking_slip_no">
                  <v-row>
                    <v-col cols="6" md="6" sm="6">
                      <h5>Picking slip no :</h5>
                    </v-col>
                    <v-col cols="6" md="6" sm="6">
                      <h5>{{shippment.picking_slip_no}}</h5>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="6" sm="6">
                  <v-btn
                    outlined
                    @click.prevent="generateAutomatically"
                    v-if="!shippment.id"
                  >Generate Automatically</v-btn>
                </v-col>
                <v-col cols="2" md="2" sm="2"></v-col>
                <v-col cols="6" md="6" sm="6">
                  <v-btn outlined @click="generateManually" v-if="!shippment.id">Generate Manually</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="auto">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    type="text"
                    v-model="shippment.tracking_code"
                    placeholder="Tracking Code"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="manual">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    type="text"
                    :style="error_track_code?'border:1px solid red':null"
                    v-model="shippment.tracking_code"
                    placeholder="Tracking Code"
                    outlined
                    dense
                    @input="validateTrackingCode"
                  ></v-text-field>
                  <span class="text-danger" v-if="error_track_code">
                    <strong>Track code aleady exist</strong>
                  </span>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    type="text"
                    prefix="https://"
                    v-model="shippment.tracking_url"
                    placeholder="Tracking Url"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="2" md="2" sm="2">
                  <v-btn outlined>Add</v-btn>
                </v-col>-->
              </v-row>
              <v-divider></v-divider>

              <div class="d-flex justify-content-around">
                <Strong>
                  <v-checkbox
                    v-model="shippment.is_signature_required"
                    label="Signature on Delivery"
                  ></v-checkbox>
                </Strong>
                <!-- <Strong>
                                  <v-checkbox label="Express Post"></v-checkbox>
                </Strong>-->
              </div>
              <div class="d-flex justify-content-around">
                <Strong>
                  <v-checkbox
                    v-model="shippment.generate_picking_slip"
                    label="Generate picking slip"
                  ></v-checkbox>
                </Strong>
                <!-- <Strong>
                                  <v-checkbox label="Express Post"></v-checkbox>
                </Strong>-->
              </div>
              <div class="d-flex justify-content-around" v-if="!shippment.id">
                <v-btn outlined dense :loading="loading" @click.prevent="createShippment">
                  Create Shipment & Notify
                  Customer
                </v-btn>
              </div>
              <v-col cols="12" md="12" sm="12" class="text-right" v-if="shippment.id">
                <span class="text-danger" @click="deleteShipment(shippment.id)">
                  <i class="fa fa-trash"></i>
                </span>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import WebsitePackageSettingService from "@/services/Websites/WebsitePackageSettingService";
import ShipmentService from "@/services/store/ShipmentService";
import WebsiteSettingService from "@/services/Websites/WebsiteSettingService";

const PackageSettingService = new WebsitePackageSettingService();
const shipmentService = new ShipmentService();
const websiteSettingService = new WebsiteSettingService();

export default {
  name: "order-packages",
  props: ["orderDetail", "loading"],
  data() {
    return {
      error_track_code: false,
      packages: [],
      selectedPackages: [],
      manual: false,
      auto: false,
      setting: {},
      default_unit: null,

      shippment: {
        order_id: null,
        website_id: null,
        shipping_id: null,
        shipping_instructions: null,
        package_ids: [],
        tracking_code: null,
        is_signature_required: true,
        shipment_status: "placed"
      }
    };
  },
  methods: {
    getShipment() {
      shipmentService
        .getByOrderId(this.orderDetail.website, this.orderDetail.id)
        .then(response => {
          this.shippment = response.data.shipment;
          this.shippment.generate_picking_slip = this.shippment.picking_slip_no
            ? true
            : false;
        })
        .catch(error => {
          // console.log(error);
        });
    },
    getWebsiteSetting() {
      websiteSettingService
        .settingByWebsiteId(this.orderDetail.website_id)
        .then(response => {
          this.setting = response.data;
          this.default_unit = response.data.default_unit;
          this.default_unit = "KG";
        })
        .catch(error => {
          // console.log(error);
        });
    },
    addPackages() {
      this.packages.push({
        id: null,
        courier_id: null,
        title: "custom",
        length: null,
        width: null,
        height: null,
        weight: null,
        weight_unit: "g",
        measurement_unit: "mm",
        type: "parcel",
        status: 1
      });
    },
    getPackages() {
      PackageSettingService.all(this.orderDetail.website)
        .then(response => {
          if (response.data.packages.length > 0) {
            this.packages = response.data.packages;
            this.getWebsiteSetting();
          }
        })
        .catch(error => {});
    },
    enablePackageItem(i, item) {
      if (item.qty == null || item.qty <= 0) {
        item.length = item.length + 1;
        item.length = item.length - 1;
        item.qty = 1;
      }
      // item.package_id = this.uniqId();
      if (item.enable) this.selectedPackages.push(item);
      else {
        this.selectedPackages.splice(this.selectedPackages.indexOf(item), 1);
      }
    },
    generateAutomatically() {
      this.shippment.tracking_code =
        Math.random(new Date().getTime())
          .toString(36)
          .substr(2, 5) +
        "-" +
        this.orderDetail.id +
        "-" +
        Math.random()
          .toString(36)
          .substr(2, 5);

      this.manual = false;
      this.auto = !this.auto;
    },
    buildPackagesIds() {
      let ids = [];
      this.selectedPackages.forEach(element => {
        ids.push(element.id);
      });
      return ids;
    },
    createShippment() {
      this.shippment.order_id = this.orderDetail.id;
      this.shippment.website_id = this.orderDetail.website_id;
      this.shippment.shipping_id = this.orderDetail.shipment.id;
      this.shippment.shipping_instructions = this.orderDetail.shipment.message;
      this.shippment.package_ids = this.selectedIds;
      shipmentService
        .create(this.orderDetail.website, this.shippment)
        .then(res => {
          // console.log(res)
          this.$snotify.success("Shippment created successfully");
          this.getShipment();
        });
      // console.log(this.shippment);
    },
    generateManually() {
      this.manual = !this.manual;
      this.auto = false;
      this.shippment.tracking_code = null;
    },
    validateTrackingCode() {
      this.loading = false;
      this.error_track_code = false;
      shipmentService
        .validateShippingTrackingCode(
          this.orderDetail.website,
          this.shippment.tracking_code
        )
        .then(response => {
          if (response.data) {
            this.loading = true;
            this.error_track_code = true;
          }
        })
        .catch(error => {
          // console.log(error);
        });
    },
    deleteShipment(id) {
        this.$confirm(
            {
                message: `Confirm delete Customer ? `,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        shipmentService.delete(this.orderDetail.website, id)
                            .then(response => {
                                this.shippment = {
                                    order_id: null,
                                    website_id: null,
                                    shipping_id: null,
                                    shipping_instructions: null,
                                    package_ids: [],
                                    tracking_code: null,
                                    is_signature_required: true,
                                    shipment_status: "in_delivery"
                                };
                            })
                            .catch(error => {
                                // console.log(error);
                            });
                    }
                }
            }
        )

    }
  },
  computed: {
    delivery_type() {
      return this.orderDetail.delivery_type
        .split("_")
        .join(" ")
        .toUpperCase();
    },
    selectedIds() {
      let ids = [];
      this.selectedPackages.forEach(element => {
        ids.push(parseInt(element.id));
      });
      return ids;
    },
    totalWeight() {
      let totalWeight = 0;
      if (this.shippment.weight) {
        // console.log(this.shippment.weight);
        return this.shippment.weight;
      }
      if (this.selectedPackages.length > 0) {
        this.selectedPackages.forEach(element => {
          let weight = element.weight;
          let weight_unit = element.weight_unit;
          let qty = parseInt(element.qty);

          if (isNaN(weight) === false && weight != "") {
            if (isNaN(qty) === false && qty != "" && qty != 0) {
              if (weight_unit == "g") {
                weight = parseFloat(weight) / 1000;
              }
              weight = parseFloat(weight) * parseFloat(qty);
            }

            totalWeight += parseFloat(weight);
          }
        });
      }
      this.shippment.weight = totalWeight;
      return totalWeight;
    }
  },

  mounted() {
    this.getPackages();
    this.getShipment();
  }
};
</script>
<style>
.packages-table td .v-input {
  margin-right: 10px;
  max-width: 75%;
  font-size: 16px;
}

.packages-table td .v-input.qty {
  max-width: 27%;
}

.packages-table td span {
  font-size: 13px !important;
  height: 40px;
}

table th,
table td {
  padding: 8px 15px !important;
}

.packages-table td .v-input--selection-controls__input {
  margin-right: 0 !important;
}
</style>
