import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteStoreOrderService {

    paginate(filter, index = null) {
        let url = API_URL + 'user/order';
        let query = new URLSearchParams(filter).toString();
        if (query)
            url = url + '?' + query
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/order/' + slug + '/store'
        return apiService.post(url, data);
    }

    update(cartId, data) {
        let url = API_URL + `user/order/${cartId}`
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/order/' + slug + '/store/' + id
        return apiService.delete(url);
    }
    show(id) {
        let url = API_URL + 'user/order/' + id
        return apiService.get(url);
    }

    updateShipping(orderId, data) {
        let url = `${API_URL}user/order/${orderId}/delivery`
        return apiService.post(url, data);
    }


}
