<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-btn outlined class="pull-right ma-1" @click="generateReport" >Download Invoice</v-btn>
              <v-btn outlined class="pull-right ma-1" onclick="window.print();">Print Invoice</v-btn>
            </v-col>
            <v-col>

              <vue-html2pdf
                      :show-layout="false"
                      :float-layout="true"
                      :enable-download="true"
                      :preview-modal="false"
                      filename="invoice"
                      :pdf-quality="2"
                      :manual-pagination="false"
                      pdf-format="a4"
                      pdf-orientation="portrait"
                      ref="html2Pdf"
              >
                <section slot="pdf-content">
                  <section class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
                    <div class="kt-portlet">
                      <div class="kt-portlet__body kt-portlet__body--fit">
                        <div class="kt-invoice-2">
                          <div class="kt-invoice__head">
                            <div class="kt-invoice__container">
                              <div class="kt-invoice__brand">
                                <h1 class="kt-invoice__title">INVOICE</h1>
                                <div href="#" class="kt-invoice__logo">
                                  <a href="#">
                                    <img src="/assets/media/logos/yelko_logo_black.png"/>
                                  </a>
                                  <span class="kt-invoice__desc pull-right"
                                        v-if="orderDetail.shipment && orderDetail.shipment.user_address">
                                <span
                                        v-if="orderDetail.shipment.user_address">{{
                                    orderDetail.shipment.user_address.full_address
                                  }}</span>
                              </span>
                                </div>
                              </div>
                              <br/>
                              <div class="kt-invoice__items">
                                <div class="kt-invoice__item">
                                  <span class="kt-invoice__subtitle">DATE</span>
                                  <span class="kt-invoice__text">{{ orderDetail.order_paid_time }}</span>
                                </div>
                                <div class="kt-invoice__item">
                                  <span class="kt-invoice__subtitle">INVOICE NO.</span>
                                  <span class="kt-invoice__text">{{ orderDetail.invoice_no }}</span>
                                </div>
                                <div class="kt-invoice__item" v-if="orderDetail.shipment">
                                  <span class="kt-invoice__subtitle">INVOICE TO.</span>
                                  <span v-if="orderDetail.shipment "
                                        class="kt-invoice__text"
                                  >{{ orderDetail.shipment.first_name }} {{ orderDetail.shipment.last_name }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="kt-invoice__body">
                            <div class="kt-invoice__container">
                              <div class="">
                                <table class="table">
                                  <thead>
                                  <tr>
                                    <th>DESCRIPTION</th>
                                    <th>Qty</th>
                                    <th>RATE</th>
                                    <th>AMOUNT</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(product,index) in orderDetail.items" :key="index">
                                    <td v-text="product.product_title"></td>
                                    <td>X {{ product.qty }}</td>
                                    <td>{{ orderDetail.cart_total.symbol }} {{ product.item_price }}</td>
                                    <td
                                            class="kt-font-primary kt-font-lg"
                                    >{{ orderDetail.cart_total.symbol }} {{ product.total_price }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Sub Total</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ orderDetail.cart_total.symbol }} {{ orderDetail.cart_total.sub_total }}</td>
                                  </tr>
                                  <tr v-if="orderDetail.discount_amount">
                                    <td>Discount</td>
                                    <td></td>
                                    <td></td>
                                    <td>- {{ orderDetail.cart_total.symbol }} {{ orderDetail.discount_amount }}</td>
                                  </tr>
                                  <tr>
                                    <td>Tax</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ orderDetail.cart_total.currency }} {{ orderDetail.cart_total.tax }}</td>
                                  </tr>
                                  <tr v-if="orderDetail.delivery_amount">
                                    <td>Delivery Charge</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ orderDetail.cart_total.symbol }} {{ orderDetail.delivery_amount }}</td>
                                  </tr>
                                  <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ orderDetail.cart_total.symbol }} {{ orderDetail.cart_total.total }}</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="kt-invoice__footer">
                            <div class="kt-invoice__container">
                              <div class="">
                                <table class="table">
                                  <thead>
                                  <tr>
                                    <th>Payment Method</th>
                                    <th>PayerName</th>
                                    <th>Paid Date</th>
                                    <th>TOTAL AMOUNT</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td>{{ payment_method ? payment_method : '' }}</td>
                                    <td>
                                      {{
                                      orderDetail.shipment ? `${orderDetail.shipment.first_name + orderDetail.shipment.last_name}` : ''
                                      }}
                                    </td>
                                    <td>{{ orderDetail.order_paid_time }}</td>
                                    <td
                                            class="kt-font-primary kt-font-xl kt-font-boldest"
                                    >{{ orderDetail.cart_total.symbol }} {{ orderDetail.cart_total.total }}
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                </section>
              </vue-html2pdf>
              <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
                <div class="kt-portlet">
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-invoice-2">
                      <div class="kt-invoice__head">
                        <div class="kt-invoice__container">
                          <div class="kt-invoice__brand">
                            <h1 class="kt-invoice__title">INVOICE</h1>
                            <div href="#" class="kt-invoice__logo">
                              <a href="#">
                                <img src="/assets/media/logos/yelko_logo_black.png"/>
                              </a>
                              <span class="kt-invoice__desc pull-right"
                                    v-if="orderDetail.shipment && orderDetail.shipment.user_address">
                                <span
                                    v-if="orderDetail.shipment.user_address">{{
                                    orderDetail.shipment.user_address.full_address
                                  }}</span>
                              </span>
                            </div>
                          </div>
                          <br/>
                          <div class="kt-invoice__items">
                            <div class="kt-invoice__item">
                              <span class="kt-invoice__subtitle">DATE</span>
                              <span class="kt-invoice__text">{{ orderDetail.order_paid_time }}</span>
                            </div>
                            <div class="kt-invoice__item">
                              <span class="kt-invoice__subtitle">INVOICE NO.</span>
                              <span class="kt-invoice__text">{{ orderDetail.invoice_no }}</span>
                            </div>
                            <div class="kt-invoice__item" v-if="orderDetail.shipment">
                              <span class="kt-invoice__subtitle">INVOICE TO.</span>
                              <span v-if="orderDetail.shipment "
                                    class="kt-invoice__text"
                              >{{ orderDetail.shipment.first_name }} {{ orderDetail.shipment.last_name }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="kt-invoice__body">
                        <div class="kt-invoice__container">
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                              <tr>
                                <th>DESCRIPTION</th>
                                <th>Qty</th>
                                <th>RATE</th>
                                <th>AMOUNT</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(product,index) in orderDetail.items" :key="index">
                                <td v-text="product.product_title"></td>
                                <td>X {{ product.qty }}</td>
                                <td>{{ orderDetail.cart_total.symbol }} {{ product.item_price }}</td>
                                <td
                                    class="kt-font-primary kt-font-lg"
                                >{{ orderDetail.cart_total.symbol }} {{ product.total_price }}
                                </td>
                              </tr>
                              <tr>
                                <td>Sub Total</td>
                                <td></td>
                                <td></td>
                                <td>{{ orderDetail.cart_total.symbol }} {{ orderDetail.cart_total.sub_total }}</td>
                              </tr>
                              <tr v-if="orderDetail.discount_amount">
                                <td>Discount</td>
                                <td></td>
                                <td></td>
                                <td>- {{ orderDetail.cart_total.symbol }} {{ orderDetail.discount_amount }}</td>
                              </tr>
                              <tr>
                                <td>Tax</td>
                                <td></td>
                                <td></td>
                                <td>{{ orderDetail.cart_total.currency }} {{ orderDetail.cart_total.tax }}</td>
                              </tr>
                              <tr v-if="orderDetail.delivery_amount">
                                <td>Delivery Charge</td>
                                <td></td>
                                <td></td>
                                <td>{{ orderDetail.cart_total.symbol }} {{ orderDetail.delivery_amount }}</td>
                              </tr>
                              <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td>{{ orderDetail.cart_total.symbol }} {{ orderDetail.cart_total.total }}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="kt-invoice__footer">
                        <div class="kt-invoice__container">
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                              <tr>
                                <th>Payment Method</th>
                                <th>PayerName</th>
                                <th>Paid Date</th>
                                <th>TOTAL AMOUNT</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td>{{ payment_method ? payment_method : '' }}</td>
                                <td>
                                  {{
                                    orderDetail.shipment ? `${orderDetail.shipment.first_name + orderDetail.shipment.last_name}` : ''
                                  }}
                                </td>
                                <td>{{ orderDetail.order_paid_time }}</td>
                                <td
                                    class="kt-font-primary kt-font-xl kt-font-boldest"
                                >{{ orderDetail.cart_total.symbol }} {{ orderDetail.cart_total.total }}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <custom-invoice :order_detail="orderDetail"></custom-invoice>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import CustomInvoice from "./CustomInvoice";
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: "product-invoices",
  props: ["orderDetail", "loading"],
  components: {
    "custom-invoice": CustomInvoice,
    VueHtml2pdf
  },
  data() {
    return {
      dialog: false,
      customInvoices: [],
      customCostomerInvoices: null,
      customInvoice: {
        invoiced_to: null,
        invoice_address: null,
        sub_total: null,
        tax_total: null,
        discount_amount: null,
        invoice_total: null,
        coupon_code: null,
        refrence_id: null,
        website_id: null,
        website_user_id: null,
        has_coupon_applied: 0,
        invoice_no: null,
        order_id: null
      },
      selectedShippmentOption: null,
      shippmentDetails: [],
      customItem: [
        {
          type: "Shipping",
          title: "",
          unit_price: 0,
          reference_id: null
        }
      ]
    };
  },
  methods: {

    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  },
  computed: {
    payment_method() {
      if (this.orderDetail && this.orderDetail.payment_method)
        return this.orderDetail.payment_method.toUpperCase();
      return null;
    },
    subTotal() {
      return this.customItem.reduce((sum, item) => {
        return (sum += parseFloat(item.unit_price));
      }, 0);
    },
    total() {
      return (
          this.subTotal +
          this.customInvoice.tax_total -
          this.customInvoice.discount_amount
      );
    }
  }
};
</script>

<style>
@media print {
  body * {
    visibility: hidden;
  }

  .kt-portlet * {
    visibility: visible;
  }

  .kt-portlet {
    position: absolute;
    top: 40px;
    left: 30px;
  }
}
</style>
