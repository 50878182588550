<template>
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="4" md="4" sm="12">
            <v-row>
              <v-col cols="12">
                <h4>Order Status</h4>
              </v-col>
              <v-col cols="6" md="6" sm="6">Payment Method</v-col>
              <v-col cols="6" md="6" sm="6">
                <span class="badge badge-primary">
                  <Strong>{{ payment_method }}</Strong>
                </span>
              </v-col>
              <v-col cols="6" v-if="orderDetail.delivery_amount" md="6" sm="6">Delivery Type</v-col>
              <v-col cols="6" v-if="orderDetail.delivery_amount" md="6" sm="6">
                <v-chip small label>
                  <Strong>{{ delivery_type }}</Strong>
                </v-chip>
              </v-col>
              <v-col cols="6" md="6" sm="6">Payment Status</v-col>
              <v-col cols="6" md="6" sm="6">
                <span
                    :class="orderDetail.payment_status=='pending'? 'badge badge-primary': 'badge badge-success'"
                >
                  <Strong>{{ payment_status }}</Strong>
                </span>
              </v-col>
              <v-col cols="6" md="6" sm="6">Order Status</v-col>
              <v-col cols="6" md="6" sm="6">
                <span
                    :class="orderDetail.order_status=='pending'? 'badge badge-primary': 'badge badge-success'"
                >{{ order_status }}</span>
              </v-col>
              <v-col v-if="orderDetail.shipment" cols="6" md="6" sm="6">Delivery Status</v-col>
              <v-col v-if="orderDetail.shipment" cols="6" md="6" sm="6">
                <span
                    :class="orderDetail.shipment.delivery_status=='pending'? 'badge badge-primary': 'badge badge-success'"
                >{{ delivery_status.toUpperCase() }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8" md="8" sm="12">
            <!-- <v-row>
              <v-col cols="12">
                <h4>Packaging & Delivery</h4>
              </v-col>
              <v-col cols="2" md="2" sm="2">Length</v-col>
              <v-col cols="2" md="2" sm="2">
                <v-chip small label>asdasd cm</v-chip>
              </v-col>
              <v-col cols="2" md="2" sm="2">Width</v-col>
              <v-col cols="2" md="2" sm="2">
                <v-chip small label>asdasd cm</v-chip>
              </v-col>
              <v-col cols="2" md="2" sm="2">Height</v-col>
              <v-col cols="2" md="2" sm="2">
                <v-chip small label>asdasd cm</v-chip>
              </v-col>
              <v-col cols="2" md="2" sm="2">Dangerous Good Declaration</v-col>
              <v-col cols="2" md="2" sm="2">
                <v-chip small label>Battery</v-chip>
              </v-col>
              <v-col cols="2" md="2" sm="2">
                <v-chip small label>Flammable</v-chip>
              </v-col>
              <v-col cols="2" md="2" sm="2">
                <v-chip small label>Liquid</v-chip>
              </v-col>
              <v-col cols="2" md="2" sm="2">
                <v-chip small label>None</v-chip>
              </v-col>
              <v-col cols="2" md="2" sm="2">
                <v-chip small label>Fragile</v-chip>
              </v-col>
            </v-row>-->
            <v-row>
              <v-col cols="12">
                <h4>Others</h4>
              </v-col>
              <v-col cols="2" md="2" sm="2">Delivery Status</v-col>
              <v-col cols="8" md="8" sm="8">
                <v-select
                    :items="delivery_statu_items"
                    v-model="delivery_status"
                    label="Delivery Status"
                    item-text="title"
                    item-value="value"
                    dense
                    outlined
                ></v-select>
              </v-col>
              <v-col cols="2" md="2" sm="2">
                <v-btn
                    @click.prevent="orderStatus()"
                    outlined
                    class="pull-right"
                    :loading="loading"
                >Update
                </v-btn>
              </v-col>

              <v-col cols="2" md="2" sm="2">Cash Received</v-col>
              <v-col cols="4" md="4" sm="4">
                <v-switch v-model="cash_received" class="mx-2" label="Cash Received"></v-switch>
              </v-col>
              <v-col cols="6" md="6" sm="6">
                <v-btn
                    :disabled="orderDetail.payment_status=='complete'"
                    @click.prevent="cashReceived()"
                    outlined
                    class="pull-right"
                    :loading="loading"
                >Update
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import EcommerceOrderStatusService from "@/services/store/EcommerceOrderStatusService";

const orderStatusService = new EcommerceOrderStatusService();
export default {
  name: "shipping",
  props: ["orderDetail", "loading"],
  data() {
    return {
      cash_received: false,
      delivery_status: null,
      delivery_statu_items: []
    };
  },
  computed: {
    // delivery_type() {
    //   if (this.orderDetail.delivery_type) {
    //     return this.orderDetail.delivery_type
    //       .split("_")
    //       .join(" ")
    //       .toUpperCase();
    //   }
    //   return null;
    // },
    payment_status() {
      if (this.orderDetail.payment_status)
        return this.orderDetail.payment_status.toUpperCase();
      return null
    },
    order_status() {
      if (this.orderDetail.order_status)
        return this.orderDetail.order_status.toUpperCase();
      return null
    },
    payment_method() {
      if (this.orderDetail.payment_method)
        return this.orderDetail.payment_method.toUpperCase();
      return null
    },
  },
  mounted() {
    this.cash_received = false;
    this.cash_received =
        this.orderDetail.payment_status == "completed" ? true : false;
    if (this.orderDetail.shipment.delivery_status)
      this.delivery_status = this.orderDetail.shipment.delivery_status
  },
  created() {
    this.getOrderStatus();
  },
  methods: {
    cashReceived() {
      let cashReceived = this.cash_received;
      let data = {payment_status: cashReceived ? "completed" : "pending"};
      this.$emit("update_cart", data);
    },
    orderStatus() {
      let data = {delivery_status: this.delivery_status};
      this.$emit("update_shipping", data);
    },
    getOrderStatus() {
      orderStatusService
          .byWebsite(this.orderDetail.website_id)
          .then(response => {
            this.delivery_statu_items = response.data.order_status;
          })
          .catch(error => {
            // console.log(error)
          });
    },
    changeDeliveryStatus() {
      let data = {
        delivery_status: this.orderDetail.shipment.delivery_status
      };
      this.$emit("save_shipping", data);
    }
  }
};
</script>
