<template>
  <div>
    <h3>Orders Management</h3>
    <v-btn
        text
        :to="{name:'manual-order'}"
        outlined
        class="btn btn-lg btn-add-main"
        style="background: transparent"
    >
      <i class="fas fa-plus-circle"></i> Add New Order
    </v-btn>
    <KTPortlet class="mt-2">
      <template v-slot:body>
        <v-app>
          <v-dialog
                  v-model="dialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
          >
            <v-card>
              <v-card>
                <v-toolbar dark color="primary" style="max-height: 64px;">
                  <v-toolbar-title>
                    <v-row v-if="orderDetail">
                      <v-col
                              cols="12"
                              md="12"
                      >{{ getBuyerName(orderDetail) }}
                      </v-col>
                      <!-- <v-col cols="3" md="3">    <span>{{ orderDetail.cart_total.currency }} {{ orderDetail.cart_total.total }}</span></v-col>
                      <v-col cols="3" md="3">   <strong>{{ orderDetail.order_paid_time | moment("from", "now") }}</strong></v-col>-->
                    </v-row>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon dark @click="closeDialog">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-tabs
                        v-if="orderDetail"
                        v-model="tab"
                        color="green"
                        centered
                        grow
                        dense
                        icons-and-text
                        style="max-height: 72px;"
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab v-if="orderDetail.type==='normal'">
                    Customer
                    <v-icon>mdi-email</v-icon>
                  </v-tab>

                  <v-tab v-if="orderDetail.type==='normal'">
                    Invoice
                    <v-icon>mdi-account-box</v-icon>
                  </v-tab>
                  <v-tab>
                    Items
                    <v-icon>mdi-cart</v-icon>
                  </v-tab>

                  <v-tab v-if="orderDetail.type==='normal' && orderDetail.shipment && orderDetail.shipment.user_address">
                    Shipment Package
                    <v-icon>mdi-account-box</v-icon>
                  </v-tab>
                  <v-tab v-if="orderDetail.type==='normal'">
                    Delivery
                    <v-icon>mdi-account-box</v-icon>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-if="orderDetail" v-model="tab">
                  <v-tab-item v-if="orderDetail.type==='normal'">
                    <customer
                            :orderDetail="orderDetail"
                            :loading="loading"
                            @save_shipping="updateShippingDetail"
                    ></customer>
                  </v-tab-item>

                  <v-tab-item v-if="orderDetail.type==='normal'">

                    <invoices
                            :orderDetail="orderDetail"
                            :loading="loading"
                            @update_cart="updateCart" data="updateShippingDetail"
                    ></invoices>
                  </v-tab-item>
                  <v-tab-item>
                    <product-detail :orderDetail="orderDetail" :loading="loading"></product-detail>
                  </v-tab-item>
                  <v-tab-item
                          v-if="orderDetail.type==='normal'  && orderDetail.shipment && orderDetail.shipment.user_address">
                    <packagesDetail :orderDetail="orderDetail" :loading="loading"></packagesDetail>
                  </v-tab-item>
                  <v-tab-item v-if="orderDetail.type==='normal'">
                    <shipping
                            :orderDetail="orderDetail"
                            :loading="loading"
                            @update_cart="updateCart"
                            @update_shipping="updateShippingDetail" data="updateShippingDetail"
                    ></shipping>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-card>
          </v-dialog>
          <div class="row" v-if="loading && orders.length<1">
            <div class="col-md-12">
              <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
            </div>
          </div>
          <!--          <pre>{{loading && orders}}</pre>-->
          <div class="row" v-if="!loading && orders.length<1">
            <div class="col-md-12 text-center">
              <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
              <h5 class="mt-10">Looks like you have not received any orders yet. Promote your website to get your first
                order.</h5>
            </div>
          </div>
          <v-row  v-if="!loading && orders.length>0">
            <v-col class="d-flex" cols="12" sm="3">
              <v-text-field dense label="Name" v-model="search.name" @input="getOrders"
                            outlined></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
              <v-text-field dense label="Phone" v-model="search.phone" outlined
                            @input="getOrders"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
              <v-text-field dense label="Email" v-model="search.email" outlined
                            @input="getOrders"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="2">
              <v-select
                  :items="websites"
                  v-model="search.website_id"
                  dense
                  label="Websites"
                  item-text="title"
                  item-value="id"
                  outlined
                  @change="getOrders"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="1">
              <v-btn @click="resetFilter()">RESET</v-btn>
            </v-col>
            <v-col class cols="12" md="12" sm="12">
              <v-data-table hide-default-footer :headers="headers" :items="orders">
                <template v-slot:item.id="{ item }">
                  <a href="#" @click.prevent="openDialog(item.id)">{{ item.invoice_no ? item.invoice_no : '' }}</a>
                </template>
                <template v-slot:item.type="{ item }">
                  <span :class="getBadgeColor(item.type)">{{ item.type.toUpperCase() }}</span>
                </template>
                <template v-slot:item.shipment="{ item }">
                  <a class="menu-link"
                     @click.prevent="openDialog(item.id)"
                  >{{ getBuyerName(item) }} </a>
                </template>
                <template v-slot:item.payment_method="{ item }">
                  <v-chip class="ma-2" small label>{{ (item.payment_method) ? item.payment_method : '-' }}
                  </v-chip>
                </template>
                <template v-slot:item.payment_status="{ item }">
                  <span :class="getBadgeColor(item.payment_status)">{{ item.payment_status }}</span>
                </template>
                <template v-slot:item.cart_total="{ item }">
                  <span>{{ item.symbol }}{{ item.cart_total.total }}</span>
                </template>

                <template v-slot:item.order_paid_time="{ item }">
                  <span>
                    <strong>{{ item.order_paid_time }}</strong>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
<!--          <v-dialog-->
<!--                  scrollable v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition"-->
<!--          >-->
<!--            <v-card>-->
<!--              <v-toolbar dark color="primary" style="max-height: 64px;">-->
<!--                <v-toolbar-title>-->
<!--                  <v-row v-if="orderDetail">-->
<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        md="12"-->
<!--                    >{{ getBuyerName(orderDetail) }}-->
<!--                    </v-col>-->
<!--                    &lt;!&ndash; <v-col cols="3" md="3">    <span>{{ orderDetail.cart_total.currency }} {{ orderDetail.cart_total.total }}</span></v-col>-->
<!--                    <v-col cols="3" md="3">   <strong>{{ orderDetail.order_paid_time | moment("from", "now") }}</strong></v-col>&ndash;&gt;-->
<!--                  </v-row>-->
<!--                </v-toolbar-title>-->
<!--                <v-spacer></v-spacer>-->
<!--                <v-toolbar-items>-->
<!--                  <v-btn icon dark @click="closeDialog">-->
<!--                    <v-icon>mdi-close</v-icon>-->
<!--                  </v-btn>-->
<!--                </v-toolbar-items>-->
<!--              </v-toolbar>-->
<!--              <v-tabs-->
<!--                  v-if="orderDetail"-->
<!--                  v-model="tab"-->
<!--                  color="green"-->
<!--                  background-color-->
<!--                  centered-->
<!--                  grow-->
<!--                  dense-->
<!--                  icons-and-text-->
<!--                  style="max-height: 72px;"-->
<!--              >-->
<!--                <v-tabs-slider></v-tabs-slider>-->

<!--                <v-tab v-if="orderDetail.type==='normal'">-->
<!--                  Customer-->
<!--                  <v-icon>mdi-email</v-icon>-->
<!--                </v-tab>-->

<!--                <v-tab v-if="orderDetail.type==='normal'">-->
<!--                  Invoice-->
<!--                  <v-icon>mdi-account-box</v-icon>-->
<!--                </v-tab>-->
<!--                <v-tab>-->
<!--                  Items-->
<!--                  <v-icon>mdi-cart</v-icon>-->
<!--                </v-tab>-->

<!--                <v-tab v-if="orderDetail.type==='normal' && orderDetail.shipment && orderDetail.shipment.user_address">-->
<!--                  Shipment Package-->
<!--                  <v-icon>mdi-account-box</v-icon>-->
<!--                </v-tab>-->
<!--                <v-tab v-if="orderDetail.type==='normal'">-->
<!--                  Delivery-->
<!--                  <v-icon>mdi-account-box</v-icon>-->
<!--                </v-tab>-->
<!--              </v-tabs>-->

<!--              <v-tabs-items v-if="orderDetail" v-model="tab">-->
<!--                <v-tab-item v-if="orderDetail.type==='normal'">-->
<!--                  <customer-->
<!--                      :orderDetail="orderDetail"-->
<!--                      :loading="loading"-->
<!--                      @save_shipping="updateShippingDetail"-->
<!--                  ></customer>-->
<!--                </v-tab-item>-->

<!--                <v-tab-item v-if="orderDetail.type==='normal'">-->

<!--                  <invoices-->
<!--                      :orderDetail="orderDetail"-->
<!--                      :loading="loading"-->
<!--                      @update_cart="updateCart" data="updateShippingDetail"-->
<!--                  ></invoices>-->
<!--                </v-tab-item>-->
<!--                <v-tab-item>-->
<!--                  <product-detail :orderDetail="orderDetail" :loading="loading"></product-detail>-->
<!--                </v-tab-item>-->
<!--                <v-tab-item-->
<!--                    v-if="orderDetail.type==='normal'  && orderDetail.shipment && orderDetail.shipment.user_address">-->
<!--                  <packagesDetail :orderDetail="orderDetail" :loading="loading"></packagesDetail>-->
<!--                </v-tab-item>-->
<!--                <v-tab-item v-if="orderDetail.type==='normal'">-->
<!--                  <shipping-->
<!--                      :orderDetail="orderDetail"-->
<!--                      :loading="loading"-->
<!--                      @update_cart="updateCart"-->
<!--                      @update_shipping="updateShippingDetail" data="updateShippingDetail"-->
<!--                  ></shipping>-->
<!--                </v-tab-item>-->
<!--              </v-tabs-items>-->
<!--            </v-card>-->
<!--          </v-dialog>-->
        </v-app>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import WebsiteStoreOrdersService from "@/services/store/WebsiteStoreOrdersService";
import WebsiteService from "@/services/Websites/WebsiteService";

import customer from "./Customer.vue";
import productDetail from "./ProductDetail";
import shipping from "./Shipping";
import PackagesDetail from "./PackagesDetail";
import Invoices from "./Invoices";


const websiteService = new WebsiteService();
const orderService = new WebsiteStoreOrdersService();

export default {
  name: "order",
  components: {
    KTPortlet,
    customer: customer,
    "product-detail": productDetail,
    shipping: shipping,
    PackagesDetail,
    Invoices
  },
  data: () => {
    return {
      loading: true,
      badge_color: null,
      orders: [],
      orderDetail: null,
      search: {},
      count: {},
      dialog: false,
      websites: [],
      tab: 1,
      setting: {},
      headers: [
        {
          text: "Orders",
          align: "start",
          sortable: false,
          value: "id"
        },
        {text: "Order Type", value: "type"},
        {text: "Date", value: "order_placed_time"},
        {text: "Customer Name", value: "shipment"},
        {text: "Payment Option", value: "payment_method"},
        {text: "Status", value: "payment_status"},
        {text: "Total", value: "cart_total"}
      ]
    };
  },
  watch: {
    $route() {
      this.search.status = this.$route.params.status;
      this.getOrders();
    }
  },
  methods: {
    getOrders() {
      this.loading = true;
      orderService.paginate(this.search).then(res => {
        this.orders = res.data.data;
        this.loading = false;
        // console.log(this.orders)
      }).catch(error => {

      }).finally(() => {
        this.loading = false;
      });
    },
    getAllWebsite() {
      websiteService
          .getAll()
          .then(response => {
            this.websites = response.data.data;
            this.loading = false;
          })
          .catch(error => {
          });
    },
    changeDeliveryStatus(cartId, status) {
      this.$confirm(
          {
            message: `Are you sure you want to change delivery status?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                let data = {delivery_status: status};
                orderService.update(cartId, data).then(res => {
                  if (res) this.getOrders();
                });
              }
            }
          }
      )

    },

    openDialog(orderId) {
      orderService.show(orderId).then(res => {
        this.orderDetail = res.data;
      });
      this.dialog = true;
    },
    updateCart(data) {
      // console.log(data)
      let cartId = this.orderDetail.id;
      this.$confirm(
          {
            message: `Are you sure you want to update cart ?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                orderService.update(cartId, data).then(res => {
                  this.$snotify.success("Updated");
                  this.getOrders();
                  this.dialog = false;
                  this.openDialog(cartId);
                });
              }
            }
          }
      )

    },
    getBadgeColor(val) {
      switch (val) {
        case "pending":
          return "badge badge-dark";
          break;
        case "processing":
          return "badge badge-primary";
          break;
        case "on_the_way":
          return "badge badge-info";
          break;
        case "delivered":
          return "badge badge-warning";
          break;
        case "complete":
          return "badge badge-success";
          break;
        case "declined":
          return "badge badge-danger";
          break;

        case "normal":
          return "badge badge-success";
          break;

        case "quote":
          return "badge badge-primary";
          break;
      }
    },
    resetFilter() {
      this.search = {};
      this.getOrders();
    },
    filterByDeliveryStatus(status) {
      this.search = {};
      this.search.delivery_status = status;
      this.getOrders();
    },
    updateShippingDetail(data) {
      // console.log(data);
      this.loading = true;
      orderService
          .updateShipping(this.orderDetail.id, data)
          .then(res => {
            this.$snotify.success("Shipment details updated");
            this.openDialog(this.orderDetail.id);
          })
          .catch(error => {
            // console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    closeDialog() {
      this.dialog = false;
      this.getOrders();
    },
    getBuyerName(item) {
      let fullName = "";
      if (item.shipment && item.shipment.first_name) {
        fullName += (item.shipment) ? item.shipment.first_name + " " : ""
        fullName += item.shipment ? item.shipment.last_name : ""
      } else {
        fullName = item.buyer
      }
      return fullName
    }
  },
  mounted() {
    this.getOrders();
    this.getAllWebsite();
  }
};
</script>

