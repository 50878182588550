<template>
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Particulars</th>
                    <th class="text-left">Price</th>
                    <th class="text-left">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product,i) in orderDetail.items" :key="product.title">
                    <td>{{product.qty}} X {{product.product_title}}</td>
                    <td>{{orderDetail.cart_total.symbol}} {{ product.item_price }}</td>
                    <td class="text-right">{{orderDetail.cart_total.symbol}} {{ product.total_price }}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      <strong>Sub Total</strong>
                    </td>
                    <td class="text-right">
                      <Strong>{{ orderDetail.cart_total.currency }} {{ orderDetail.cart_total.sub_total }}</Strong>
                    </td>
                  </tr>
                  <tr v-if="orderDetail.discount_amount">
                    <td></td>
                    <td>
                      <strong>Discount</strong>
                    </td>
                    <td class="text-right">
                      <Strong>- {{orderDetail.cart_total.symbol}} {{orderDetail.discount_amount}}</Strong>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <strong>Tax</strong>
                    </td>
                    <td class="text-right">
                      <Strong>{{ orderDetail.cart_total.currency }} {{ orderDetail.cart_total.tax }}</Strong>
                    </td>
                  </tr>
                  <tr v-if="orderDetail.delivery_amount">
                    <td></td>
                    <td>
                      <strong>Delivery Chanrge</strong>
                    </td>
                    <td class="text-right">
                      <Strong>{{orderDetail.cart_total.symbol}} {{ orderDetail.delivery_amount }}</Strong>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td class="text-right">
                      <Strong>{{ orderDetail.cart_total.currency }} {{ orderDetail.cart_total.total }}</Strong>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "product-detail",
  props: ["orderDetail", "loading"]
};
</script>