<template>
  <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet">
      <div class="kt-portlet__body kt-portlet__body--fit">
        <div class="kt-invoice-2">
          <div class="kt-invoice__head">
            <div class="kt-invoice__container">
              <div class="kt-invoice__brand">
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-lg-6">
                    <h1 class="kt-invoice__title">CUSTOM INVOICE</h1>
                  </div>
                  <div class="col-md-6 col-sm-6 col-lg-6 text-right">
                    <v-btn
                      outlined
                      title="Add New Custom Invoice"
                      class="ml-2"
                      @click="dialog = true;"
                    >
                      <i class="fas fa-plus"></i>
                    </v-btn>
                    <v-btn
                      v-if="customInvoice"
                      outlined
                      title="Add New Custom Invoice"
                      class="ml-2 text-danger"
                      @click="deleteInvoice"
                    >
                      <i class="fas fa-trash"></i>
                    </v-btn>
                  </div>
                </div>
                <!-- <v-btn
                    outlined
                    class="float-right ma-1"
                    :disabled="customCostomerInvoices!==null"
                    @click="dialog = true;"
                >Add New Custom Invoice</v-btn>-->
              </div>
              <br />
            </div>
          </div>
          <div class="kt-invoice__body">
            <div class="kt-invoice__container">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th width="30%">Type</th>
                          <th width="30%">DESCRIPTION</th>
                          <th width="20%">AMOUNT</th>
                          <th width="20%">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item,index) in customInvoice.items" :key="index">
                          <td>{{ item.type }}</td>
                          <td>{{ item.title }}</td>
                          <td class="kt-font-primary kt-font-lg">{{symbol}} {{ item.unit_price }}</td>
                          <td @click="deleteItem(item.id)">
                            <i class="fas fa-trash"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog scrollable v-model="dialog" persistent max-width="900">
      <v-card>
        <v-card-title class="headline">Add New Custom Invoice</v-card-title>
        <v-card-text>
          <!-- <v-row>
            <v-col>
              <v-text-field label="Item title"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Item Price" prefix="Rs."></v-text-field>
            </v-col>
          </v-row>-->
          <v-row>
            <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
              <div class="kt-portlet">
                <div class="kt-portlet__body kt-portlet__body--fit">
                  <div class="kt-invoice-2">
                    <div class="kt-invoice__body">
                      <div class="kt-invoice__container">
                        <div class="row">
                          <div class="col-sm-4 col-md-4 col-lg-4">
                            <v-select
                              v-model="item.type"
                              outlined
                              dense
                              label="Item Type"
                              :items="['Shipping','Other']"
                            ></v-select>
                            <span class="text-danger" v-if="$v.item.type.$error">Type is required</span>
                          </div>

                          <div class="col-sm-4 col-md-4 col-lg-4">
                            <v-select
                              v-if="item.type === 'Shipping'"
                              outlined
                              dense
                              @change="changeShipmentOption(selectedShippmentOption,index)"
                              :items="shippmentDetails"
                              v-model="selectedShippmentOption"
                              return-object
                              item-text="title"
                              label="Available Shipping Options"
                            ></v-select>
                            <v-text-field
                              v-else
                              v-model="item.title"
                              outlined
                              dense
                              label="Item Title"
                            ></v-text-field>
                            <span class="text-danger" v-if="$v.item.title.$error">Title is required</span>
                          </div>
                          <div class="col-sm-4 col-md-4 col-lg-4">
                            <v-text-field
                              v-model.number="item.unit_price"
                              :prefix="symbol"
                              type="number"
                              outlined
                              dense
                              label="Item Total"
                            ></v-text-field>
                            <span
                              class="text-danger"
                              v-if="$v.item.title.$error"
                            >Unit price is required</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="resetCustomInvoice">cancel</v-btn>
          <v-btn color="green darken-1" text :loading="loading" @click="createOrUpdate()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <pre>{{invoice}}</pre>
    <pre>{{customInvoice}}</pre> -->
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import WebsiteShippingDetails from "@/services/Websites/WebsiteShippingDetails";
import EcommerceCustomInvoiceService from "@/services/store/EcommerceCustomInvoiceService";

const WebsiteShipping = new WebsiteShippingDetails();
const CustomInvoiceService = new EcommerceCustomInvoiceService();

export default {
  name: "custom-invoice",
  props: ["order_detail"],
  validations: {
    item: {
      type: { required },
      title: { required },
      unit_price: { required }
    }
  },
  data() {
    return {
      symbol: null,
      invoice: {},
      customInvoice: {},
      edit: false,
      loading: false,
      dialog: false,
      reloadNeeded: true,
      item: {
        type: null,
        title: null,
        unit_price: null
      }
    };
  },
  computed: {},
  mounted() {
    this.symbol = this.order_detail.cart_total.symbol;
  },
  updated() {
    if (this.reloadNeeded) {
      this.getShippingDetails();
      this.getCustomInvoice();
      this.reloadNeeded = false;
    }
  },
  methods: {
    buildInvoice() {
      this.invoice = {
        order_id: this.order_detail.id,
        invoiced_to:
          this.order_detail.shipment.first_name +
          " " +
          this.order_detail.shipment.last_name,
        invoice_address: this.order_detail.shipment.user_address.full_address,
        website_id: this.order_detail.website_id,
        website_user_id: this.order_detail.buyer_user_id
      };
    },
    getCustomInvoice() {
      CustomInvoiceService.show(
        this.order_detail.website,
        this.order_detail.id
      ).then(res => {
        if (res.data) {
          this.customInvoice = res.data.data;
          this.edit = true;
        } else {
          this.customInvoice = false;
        }
        // console.log();
        this.buildInvoice();
      });
    },
    getShippingDetails() {
      WebsiteShipping.paginate(this.order_detail.website)
        .then(res => {
          // console.log(res);
          if (res.data.length > 0) {
            this.shippmentDetails = res.data;
          }
        })
        .catch(err => {});
    },
    changeShipmentOption(item, index) {
      this.customItem[index].title = item.title;
      this.customItem[index].unit_price = item.delivery_charge;
      this.customItem[index].reference_id = item.id;
      this.selectedShippmentOption = null;
    },
    createOrUpdate() {
      this.$v.item.$touch();
      if (this.$v.item.$error) {
        setTimeout(() => {
          this.loading = false;
          this.$v.item.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.update();
        } else {
          this.save();
        }
      }
    },
    save() {
      let data = { custom_invoice: this.invoice, item: this.item };
      CustomInvoiceService.create(this.order_detail.website, data)
        .then(res => {
          this.$snotify.success("Custom Invoice Created");
          this.resetCustomInvoice();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
          let data = { item: this.item };
      CustomInvoiceService.update(this.order_detail.website, this.customInvoice.id, data)
        .then(res => {
          this.$snotify.success("Item added to custom invoice");
          this.resetCustomInvoice();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteInvoice() {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        CustomInvoiceService.delete(
                            this.order_detail.website,
                            this.customInvoice.id
                        )
                            .then(res => {
                                this.$snotify.success("Invoice deleted");
                                this.resetCustomInvoice();
                            })
                            .catch(error => {
                                // console.log(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                }
            }
        )

    },
    deleteItem(itemId) {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        CustomInvoiceService.deleteItem(
                            this.order_detail.website,
                            this.customInvoice.id,
                            itemId
                        )
                            .then(res => {
                                this.$snotify.success("Invoice Item deleted");
                                this.resetCustomInvoice();
                            })
                            .catch(error => {
                                // console.log(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                }
            }
        )

    },
    resetCustomInvoice() {
      this.dialog = false;
      this.edit = false;
      this.invoice = {};
      this.item = {
        type: null,
        title: null,
        unit_price: null
      };
      this.getShippingDetails();
      this.getCustomInvoice();
      this.buildInvoice();
      this.reloadNeeded = true;
    }
  }
};
</script>
